import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"

import * as S from "../../components/Pages/general"

const MaisCursosPage = () => {
  const { colorindoImage, combo } = useStaticQuery(
    graphql`
      query {
        colorindoImage: file(relativePath: { eq: "colorindo-fanart.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        combo: file(relativePath: { eq: "combo-ultimate-x.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  console.log(colorindoImage)

  return (
    <Layout>
      <SEO
        title="Mais Cursos"
        description="Se você busca aprimorar ainda mais seus traços, aqui oferecemos uma gama maior de cursos e alguns combos que podem te ajudar a evoluir ainda mais nesse caminho."
      />
      <S.GeneralWrapper>
        <S.GeneralTitle>Mais Cursos </S.GeneralTitle>
        <S.GeneralText>
          Nessa sessão do nosso site iremos trazer sempre alguns cursos
          complementares onde você pode estar aprimorando ainda mais o seus
          conhecimentos, sejam eles de pintura, cenários, criação de personagens
          entre outros.
        </S.GeneralText>
        <S.GeneralText>
          Algumas indicações também poderam ser de combos onde será incluído
          vários dos cursos acima citado, então fique sempre de olho aqui e
          aproveite ainda mais essa oportunidade para aprender a desenhar.
        </S.GeneralText>
        <S.GeneralItem to="./colorindo-fan-art">
          <div className="texto">
            <S.GeneralSubTitle>Colorindo Fan Art</S.GeneralSubTitle>
            <S.GeneralText>
              Um super combo 2x1, na compra do curso Colorindo Fan Art você
              também leva o{" "}
              <S.GeneralStrongText>
                curso de Criando seu Próprio Personagem de Anime.
              </S.GeneralStrongText>
            </S.GeneralText>
          </div>
          <S.GeneralImageCursos
            fluid={colorindoImage.childImageSharp.fluid}
            alt="Imagem com o título de colorindo Fan art e uma personagem de anime ao funto"
          />
        </S.GeneralItem>
        <S.GeneralItem
          box="0 0 57px 11px var(--orange_logo)"
          to="./combo-ultimate"
        >
          <div className="texto">
            <S.GeneralSubTitle>Combo Ultimate X </S.GeneralSubTitle>
            <S.GeneralText>
              Um super combo de 6 cursos da Mayara Rodrigues com 40% de
              desconto.{" "}
              <S.GeneralStrongText>
                {" "}
                Todos com acesso vitalício.
              </S.GeneralStrongText>
            </S.GeneralText>
          </div>
          <S.GeneralImageCursos
            fluid={combo.childImageSharp.fluid}
            alt="Imagem com o título de combo ultimate e vários personagens de anime ao funto"
          />
        </S.GeneralItem>
      </S.GeneralWrapper>
    </Layout>
  )
}

export default MaisCursosPage
